import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'app/modules/react-redux';
import ErrorMessageView from 'app/components/ErrorMessageView';
import * as Actions from 'app/store/actions';
import { getCatalog, getIsSupportedRegion, getProfile, getRegion } from 'app/store/reducers';
import NotAvailablePage from 'app/main/not-available/NotAvailablePage';
import { Redirect, useLocation } from 'react-router';

type Status = 'loading' | 'success' | 'error';
type Props = {
	children: React.ReactNode;
};

// FIXME::WARNING::this component cannot contain `useTranslation`. For some reason React.Suspense is not stopping this from rendering.
const InitializeApp = ({ children }: Props) => {
	const dispatch = useDispatch();
	const isSupportedRegion = useSelector(getIsSupportedRegion);
	const profile = useSelector(getProfile) as ReturnType<typeof getProfile> | undefined; // HACK::use actual type here - assume never undefined within the wrapped app;
	const region = useSelector(getRegion) as ReturnType<typeof getRegion> | undefined; // HACK::use actual type here - assume never undefined within the wrapped app;
	const catalog = useSelector(getCatalog) as ReturnType<typeof getCatalog> | undefined; // HACK::use actual type here - assume never undefined within the wrapped app;
	const dataFetched = profile && region && catalog && profile.tokens.expirationDate < Date.now();
	const [status, setStatus] = useState<Status>('loading');
	const location = useLocation();

	useEffect(() => {
		Promise.all([
			dispatch(Actions.getProfile()),
			// dispatch(Actions.getAdminedLicenseGroups()),
			// FIXME::why is `.then` not working?
			(dispatch(Actions.getRegion()) as any).then(() => dispatch(Actions.getCatalog()))
		])
			.then(() => {
				setStatus('success');
			})
			.catch(() => {
				setStatus('error');
			});
	}, [dispatch]);

	// HACK-ish::use session storage for SSO redirect
	const redirectPath = sessionStorage.getItem('redirect');

	if (redirectPath) {
		sessionStorage.removeItem('redirect');
		if (!location.pathname.includes('/error')) {
			return <Redirect to={redirectPath} />;
		}
	}

	if (status === 'success' || dataFetched) {
		return <>{children}</>;
	}

	if (status === 'error') {
		return <ErrorMessageView />;
	}

	if (!isSupportedRegion) {
		return <NotAvailablePage />;
	}

	return <FuseSplashScreen />;
};

export default InitializeApp;
