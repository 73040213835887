import axios from 'axios';
import mocking from './mocking';

const instance = axios.create({
	// baseURL: 'http://localhost:9200', // do not prepend URL because it can be determined from browser
	responseType: 'json',
	// auth: {
	// 	username: 'a1a1a1a1-a1a1-a1a1-a1a1-a1a1a1a1a1a1',
	// 	password: ''
	// },
	headers: {
		// Authorization: 'Basic ZjRjOWIxYzNkYjhmMDkzZmQ3MmY0ZjY3ZmViZjFmOTUxYzI1ZDBjMjox',
		'Content-Type': 'application/json'
		// DEV NOTE::`Authentication` header bearer token is added after `getProfile` call in `profile.actions.ts`
	}
});

export default instance;

mocking(instance);
