import clsx from 'clsx';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from 'app/components/DialogTitle';
import AppBar, { AppBarProps } from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import StandardButton from '../StandardButton';

const useStyles = makeStyles(theme => ({
	dialog: {
		'& .MuiPaper-rounded': {
			borderRadius: 4
		},
		'& .MuiDialog-paper': {
			// overflowY: 'visible'
		},
		'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
			borderColor: '#f44336'
		}
	},
	errorDialogHeader: {
		color: '#FFFFFF !important',
		backgroundColor: '#A32525 !important'
	},
	dialogContent: {
		fontSize: '1.4rem',
		whiteSpace: 'pre-line',
		marginBottom: '8px'
	},
	dialogTitle: {
		whiteSpace: 'nowrap',
		overflowX: 'hidden',
		textOverflow: 'ellipsis'
	},
	button: {
		'&.MuiButton-root': {
			padding: '0 38px',
			borderRadius: 5
		},
		'&.MuiButton-outlinedSecondary': {
			border: `2px solid ${fade(theme.palette.secondary.main, 0.5)}`
		},
		'&.MuiButton-outlinedSecondary:hover': {
			border: `2px solid ${theme.palette.secondary.main}`
		},
		'&.MuiButton-containedSecondary': {
			border: `2px solid ${fade(theme.palette.secondary.main, 0.5)}`
		},
		'&.MuiButton-containedSecondary:hover': {
			border: '2px solid rgb(71, 44, 89)'
		}
	},
	resource: {
		overflow: 'visible',
		marginTop: 8,
		paddingTop: 0,
		paddingBottom: 0,
		paddingRight: 23,
		paddingLeft: 23
	},
	input: {
		'& .MuiInputBase-root': {
			height: 36.5
		}
	}
}));

type Props = {
	open: boolean;
	setOpen: (open: boolean) => void;
	onOpen?: Function;
	onClose?: Function;
	title?: string;
	content?: React.ReactNode;
	action: (params?: any) => void;
	buttonsText?: [any?, any?];
	type?: 'error';
	children?: React.ReactElement;
	contentTextVariant?: any;
	leftButton?: { text: string; action: () => void };
	tertiaryButton?: { text: string; action: () => void };
	resource?: any;
	input?: any;
	disabledBtn?: boolean;
	overflowWithin?: boolean;
	maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
	backFn?: () => void;
	actionPaddingTopPx?: number;
	resourceMarginBottom?: number;
	secondaryButton?: boolean;
	noOverflow?: boolean;
	titleTooltip?: boolean;
	bonus?: any;
	noButton?: boolean;
	className?: string;
	customButton?: React.ReactElement;
	titleIcon?: React.ReactNode;
	appBarProps?: AppBarProps;
};

const AlertDialog = ({
	open,
	setOpen,
	onOpen = () => {},
	onClose = () => {},
	children,
	title,
	content,
	action,
	buttonsText = [],
	type = undefined,
	contentTextVariant = 'caption',
	tertiaryButton,
	leftButton,
	actionPaddingTopPx,
	resourceMarginBottom,
	resource = null,
	input = null,
	disabledBtn = false,
	overflowWithin,
	maxWidth = 'xs',
	backFn,
	secondaryButton = true,
	noOverflow,
	titleTooltip = false,
	bonus,
	noButton = false,
	className = '',
	customButton,
	titleIcon,
	appBarProps = {}
}: Props) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const [primaryButtonText = t('OK'), secondaryButtonText = t('Cancel')] = buttonsText;

	const handleOpen = () => {
		onOpen();
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		onClose();
	};

	return (
		<>
			{children
				? React.cloneElement(children, {
						onClick: (e: any) => {
							// eslint-disable-next-line no-unused-expressions
							e?.preventDefault?.();
							handleOpen();
						}
				  })
				: undefined}
			{open && (
				<Dialog
					open={!!open}
					onClose={handleClose}
					className={`md ${classes.dialog} ${className}`}
					fullWidth
					maxWidth={maxWidth}
					aria-labelledby="dialog-title"
					disableRestoreFocus
				>
					<AppBar
						position="static"
						elevation={0}
						className={clsx({ [classes.errorDialogHeader]: type === 'error' }, appBarProps.className)}
						{...appBarProps}
					>
						{title && (
							<DialogTitle onClose={handleClose} className="flex w-full justify-center">
								<div className="flex flex-row">
									{titleIcon && (
										<div className="flex -ml-16 mr-8 items-center h-auto">{titleIcon}</div>
									)}
									<Tooltip placement="bottom" title={titleTooltip ? title : ''}>
										<Typography
											className={`text-base font-bold ${classes.dialogTitle}`}
											variant="subtitle1"
											color="inherit"
										>
											{title}
										</Typography>
									</Tooltip>
								</div>
							</DialogTitle>
						)}
					</AppBar>

					<main style={{ overflowY: noOverflow ? 'unset' : 'auto' }}>
						{(content || input) && (
							<DialogContent
								style={{ padding: '23px 23px 0 23px' }}
								classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}
							>
								{content && (
									<DialogContentText
										className={classes.dialogContent}
										variant={contentTextVariant}
										color="inherit"
									>
										{content}
									</DialogContentText>
								)}
								{input && (
									<div
										style={{ paddingBottom: 23, paddingTop: 8, float: 'right', width: '100%' }}
										className={`form-group flex gap-20 ${classes.input}`}
									>
										{input}
										{/* <Button
								variant="contained"
								color="secondary"
								type="submit"
								onClick={action}
								disabled={disabledBtn}
								className="w-1/4 py-0 px-16"
								style={{ maxHeight: 31 }}
							>
								{primaryButtonText}
							</Button> */}
										<StandardButton
											btnText={primaryButtonText}
											onClick={action}
											disabled={disabledBtn}
										/>
									</div>
								)}
							</DialogContent>
						)}

						{resource && (
							<DialogContent
								className={`${classes.resource} ${
									overflowWithin ? 'overflow-scroll mt-0' : ''
								} mb-${resourceMarginBottom ?? 8}`}
							>
								{resource}
							</DialogContent>
						)}
					</main>

					{!input && (
						<DialogActions
							style={{ padding: `${actionPaddingTopPx ?? '8'}px 23px 23px 23px` }}
							className="px-24 pb-20"
							disableSpacing
						>
							{/* <Button
						className={classes.button}
						onClick={e => {
							e.preventDefault();
							handleClose();
						}}
						variant="outlined"
						color="secondary"
					>
						{secondaryButtonText}
					</Button>
					<Button
						className={classes.button}
						onClick={e => {
							e.preventDefault();
							action();
							handleClose();
						}}
						variant="contained"
						color="secondary"
						autoFocus
					>
						{primaryButtonText}
					</Button> */}
							<div style={{ position: 'absolute', left: 23 }}>{bonus}</div>
							{backFn && (
								<StandardButton
									className="mr-12"
									btnText={t('Back')}
									onClick={backFn}
									btnType="secondary"
									style={{ position: 'absolute', left: 23 }}
								/>
							)}
							{leftButton && (
								<StandardButton
									className="mr-12"
									btnText={leftButton.text}
									onClick={leftButton.action}
									btnType="secondary"
									style={{ position: 'absolute', left: 23 }}
								/>
							)}
							{tertiaryButton && (
								<StandardButton
									className="mr-12"
									btnText={tertiaryButton.text}
									onClick={tertiaryButton.action}
									btnType="secondary"
								/>
							)}
							{((resource && buttonsText[1]) || !resource) && secondaryButton && secondaryButtonText && (
								<StandardButton
									className="mr-12"
									btnText={secondaryButtonText}
									onClick={handleClose}
									btnType="secondary"
								/>
							)}
							{!noButton && (
								<StandardButton
									btnText={primaryButtonText}
									disabled={disabledBtn}
									onClick={(e: any) => {
										action(e);
										setOpen(false);
									}}
								/>
							)}
							{customButton}
						</DialogActions>
					)}
				</Dialog>
			)}
		</>
	);
};

export default AlertDialog;
