/* eslint-disable import/no-cycle */
import axios from 'app/client';
import { arrayify, getMetadataUrl, getNodeTestingAPI, groupByID, getApproveNodesUrl } from 'app/utils/helpers';
import { getProfile, getTrackedJobs, getSelectedLicenseGroupId } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import _ from 'lodash';
import * as appActions from './app.actions';
import { InboxItem, JobItemDetails, JobNote, JobQueue, User, UserGroup } from '../types';

export const GET_USER_JOBS_SUCCESS = 'GET_USER_JOBS_SUCCESS';
export const GET_SELECTED_JOB_SUCCESS = 'GET_SELECTED_JOB_SUCCESS';
export const GET_SELECTED_QUEUE_SUCCESS = 'GET_SELECTED_QUEUE_SUCCESS';
export const RESET_SELECTED_JOB_SUCCESS = 'RESET_SELECTED_JOB_SUCCESS';
export const GET_USER_QUEUES_SUCCESS = 'GET_USER_QUEUES_SUCCESS';
export const GET_TENANT_QUEUES_SUCCESS = 'GET_TENANT_QUEUES_SUCCESS';
export const GET_QUEUE_JOBS_SUCCESS = 'GET_QUEUE_JOBS_SUCCESS';
export const GET_USER_INBOX_SUCCESS = 'GET_USER_INBOX_SUCCESS';
export const ACCEPT_JOB_SUCCESS = 'ACCEPT_JOB_SUCCESS';
export const GET_TRACKER_SUCCESS = 'GET_TRACKER_SUCCESS';
export const TRACK_JOB_SUCCESS = 'TRACK_JOB_SUCCESS';
export const UNTRACK_JOB_SUCCESS = 'UNTRACK_JOB_SUCCESS';
export const GET_DROP_DATA_SUCCESS = 'GET_DROP_DATA_SUCCESS';

const findErrors = (res: any) => {
	const err: any = [];
	arrayify(res).forEach((response: any) => {
		if (Array.isArray(response.data)) {
			response.data.forEach((datum: any) => {
				if (datum.type) {
					err.push(datum.type);
				}
			});
		}
	});
	return err;
};

const massageRawJob = (rawJob: any) => {
	const job: JobItemDetails = {
		...rawJob,
		attachments:
			rawJob.attachments?.map((attachment: any) => ({
				...attachment,
				metadata: {
					...attachment.metadata,
					// HACK::some extension are missing a prefixed `.` - the site now assumes none of them have one
					...(attachment.metadata.ext ? { ext: attachment.metadata.ext.replace(/^\./, '') } : {})
				},
				fileId: attachment.url
					.split('?')[0]
					.split('/')
					.pop()
			})) ?? []
	};

	return job;
};

export const getUserJobs = (onSuccess = () => {}, onError = () => {}): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	try {
		const response = await axios.get(`${jobsApiUrl}/people/jobs/user`, {
			params: {
				userID: profile.id
			}
		});
		dispatch({
			type: GET_USER_JOBS_SUCCESS,
			payload: groupByID(response.data.jobs)
		});
		onSuccess();
	} catch (error) {
		dispatch(appActions.handleError(error, 'jobs:get:fail'));
		onError();
	}
};

export const getSelectedJob = (jobID: string, onSuccess = () => {}, onError?: Function): AppThunk => async (
	dispatch,
	getState
) => {
	console.log('getting job', jobID);
	const profile = getProfile(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	try {
		const { data } = await axios.get(`${jobsApiUrl}/people/job`, {
			params: {
				jobID
			}
		});
		console.log('selectedJob', data.job);
		console.log('selectedJob - massaged', massageRawJob(data.job));
		dispatch({
			type: GET_SELECTED_JOB_SUCCESS,
			payload: massageRawJob(data.job)
		});
		onSuccess();
	} catch (error) {
		console.log(error);
		if (onError) {
			onError();
		} else {
			dispatch(appActions.handleError(error, 'job:get:fail'));
		}
	}
};

export const fetchJobDropData = (jobID: string, onSuccess = () => {}, onError = () => {}): AppThunk => async (
	dispatch,
	getState
) => {
	const profile = getProfile(getState());
	const apiUrl = getNodeTestingAPI(profile?.awsRegion);
	try {
		const processResponse = await axios.get(`${apiUrl}/workflow/process/${jobID}`);
		const dropData = processResponse.data.process.drop;
		dispatch({
			type: GET_DROP_DATA_SUCCESS,
			payload: dropData
		});

		onSuccess();
	} catch (error) {
		dispatch(appActions.handleError(error, 'dropData:get:fail'));
		onError();
	}
};

export const resetSelectedJob = (): AppThunk => async (dispatch, getState) => {
	dispatch({
		type: RESET_SELECTED_JOB_SUCCESS
	});
};

export const getUserQueues = (onSuccess = () => {}, onError = () => {}): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	try {
		const response = await axios.get(`${jobsApiUrl}/people/queues/user`, {
			params: {
				userID: profile.id
			}
		});
		const { data } = response;
		console.log('user queues', data.queues);
		dispatch({
			type: GET_USER_QUEUES_SUCCESS,
			payload: groupByID(data.queues)
		});
		onSuccess();
	} catch (error) {
		dispatch(appActions.handleError(error, 'queues:get:fail'));
		onError();
	}
};
export const getTenantQueues = (): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	try {
		const response = await axios.get(`${jobsApiUrl}/people/queues`);
		const { data } = response;
		console.log('tenant queues', data.queues);
		dispatch({
			type: GET_TENANT_QUEUES_SUCCESS,
			payload: groupByID(data.queues)
		});
	} catch (error) {
		dispatch(appActions.handleError(error, 'queues:get:fail'));
	}
};

export const getQueueJobs = (queueID: string, onSuccess = () => {}, onError = () => {}): AppThunk => async (
	dispatch,
	getState
) => {
	const profile = getProfile(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	try {
		const response = await axios.get(`${jobsApiUrl}/people/jobs/queue`, {
			params: {
				queueID
			}
		});
		const { data } = response;
		console.log('queue', queueID, 'jobs', data.jobs);
		dispatch({
			type: GET_QUEUE_JOBS_SUCCESS,
			payload: {
				id: queueID,
				jobs: groupByID(data.jobs)
			}
		});
		onSuccess();
	} catch (error) {
		dispatch(appActions.handleError(error, 'queue:jobs:get:fail'));
		onError();
	}
};

export const getJobNotes = (jobId: string): AppThunk => async (dispatch, getState) => {};

export const getJobHistory = (jobId: string): AppThunk => async (dispatch, getState) => {};

export const removeJob = (jobId: string): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const tenantId = getSelectedLicenseGroupId(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	try {
		await axios.delete(`${jobsApiUrl}/people/job`, {
			params: {
				tenantID: tenantId,
				jobID: jobId
			}
		});
	} catch (error) {
		dispatch(appActions.alert('jobs:job remove:fail', 'warning'));
	}
	dispatch(appActions.alert('jobs:job remove:success', 'success'));
	dispatch(getUserJobs());
};

const massageRawInboxItem = (rawInboxItem: any) => {
	// HACK::capture values inside single quotes or at end of string with colon and treat them as variables
	const messageToMessageKey = (message: string) => {
		const pattern = /'([^']*)'|: ([^\s]+)$/g;

		const matches = message.match(pattern) ?? [];
		let counter = 0;

		const messageKey: InboxItem['messageKey'] = message.replace(pattern, () => `{{${counter++}}}`);

		const info = matches.reduce((acc, match, index) => {
			acc[index] = match;
			return acc;
		}, {} as InboxItem['info']);

		return {
			messageKey,
			info
		};
	};

	const { messageKey, info } = messageToMessageKey(rawInboxItem.message);

	const inboxItem: InboxItem = {
		id: rawInboxItem.id,
		type: rawInboxItem.type,
		status: rawInboxItem.status,
		job: rawInboxItem.job,
		origin: rawInboxItem.origin,
		createdAt: rawInboxItem.createdAt,
		// message: rawInboxItem.message,
		messageKey,
		info
	};
	return inboxItem;
};

export const getInbox = (onSuccess = () => {}, onError = () => {}): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	try {
		const response = await axios.get(`${jobsApiUrl}/people/inbox`, {
			params: {
				userID: profile.id
			}
		});

		const inbox: { [id: string]: InboxItem } = _.mapValues(_.keyBy(response.data.inbox, 'id'), inboxItem =>
			massageRawInboxItem(inboxItem)
		);

		dispatch({
			type: GET_USER_INBOX_SUCCESS,
			payload: inbox
		});
		onSuccess();
	} catch (error) {
		dispatch(appActions.handleError(error, 'inbox:get:fail'));
		onError();
	}
};
export const deleteInboxItem = (itemId: string, onSuccess = () => {}, onError = () => {}): AppThunk => async (
	dispatch,
	getState
) => {
	const profile = getProfile(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	try {
		await axios.delete(`${jobsApiUrl}/people/inbox`, {
			params: {
				userID: profile.id,
				item: itemId
			}
		});
		onSuccess();
	} catch (error) {
		dispatch(appActions.handleError(error, 'inbox:delete:fail'));
		onError();
	}
	dispatch(getInbox());
};

export const postNote = (
	note: Pick<JobNote, 'note' | 'private'>,
	jobID: string,
	onSuccess = () => {},
	onError = () => {}
): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	try {
		await axios.post(`${jobsApiUrl}/people/job/notes`, {
			jobID,
			userID: profile.id,
			note: note.note,
			private: note.private
		});
		dispatch(getSelectedJob(jobID));
		onSuccess();
	} catch (error) {
		dispatch(appActions.handleError(error, 'note:post:fail'));
		onError();
	}
};

export const removeAttachments = (fileIds: string[], job: JobItemDetails, onError = () => {}): AppThunk => async (
	dispatch,
	getState
) => {
	const profile = getProfile(getState());
	const metadataApiUrl = getMetadataUrl(profile?.awsRegion);
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	const droppoint = job.drop as { id: string; username: string; password: string };
	try {
		const ntfResponses = await Promise.all(
			fileIds.map(fileId =>
				axios.delete(`${jobsApiUrl}/delete-file`, {
					data: {
						filename: fileId,
						dropid: droppoint.id,
						username: droppoint.username,
						password: droppoint.password
					}
				})
			)
		);
		const metadataResponses = await Promise.all(
			fileIds.map(fileId => axios.delete(`${metadataApiUrl}/api/v1/file/${job.id}/${fileId}`))
		);

		const fileMetadataReponses = await axios.patch(
			`${metadataApiUrl}/api/v1/process/${job.id}/${job.workflow.id}`,
			{
				files: fileIds
			}
		);

		const ntfErr: any = findErrors(ntfResponses);
		const metadataErr: any = findErrors(metadataResponses);
		const metadataFileErr: any = findErrors(fileMetadataReponses);
		if (!_.isEmpty(ntfErr)) {
			console.log('Error in removeAttachment:', ntfErr);
			onError();
			return false;
		}
		if (!_.isEmpty(metadataErr)) {
			console.log('Error in removeAttachment:', metadataErr);
			onError();
			return false;
		}
		if (!_.isEmpty(metadataFileErr)) {
			console.log('Error in removeAttachment:', metadataFileErr);
			onError();
			return false;
		}
		if (ntfResponses.every(response => response.data.result !== 'success')) {
			dispatch(appActions.alert('jobs:file remove:fail', 'warning'));
			onError();
			return false;
		}

		const deletionResponse = await axios.delete(`${jobsApiUrl}/people/job/attachments`, {
			params: {
				jobID: job.id,
				files: fileIds.join(',')
			}
		});
		const JobsErr: any = findErrors(deletionResponse);
		if (!_.isEmpty(JobsErr)) {
			dispatch(appActions.alert('jobs:file remove:fail', 'warning'));
			onError();
		}
		dispatch(getSelectedJob(job.id));
		dispatch(appActions.alert('jobs:file remove:success', 'success'));
		return deletionResponse.data;
	} catch (error) {
		console.error('Error in removeAttachment:', error);
		onError();
		return false;
	}
};

export const postAttachment = (
	files: File[],
	job: JobItemDetails,
	workflow: any,
	setTotalProgress: Function,
	setProgressData: Function,
	onSuccess: Function,
	onError: Function
): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const tenantId = getSelectedLicenseGroupId(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	const fileResponses = [];
	const stage =
		process.env.REACT_APP_ENVIRONMENT === 'development'
			? 'DEV'
			: process.env.REACT_APP_ENVIRONMENT === 'testing'
			? 'STAGING'
			: 'PROD';

	// const mockmockProgress = (resolve: any, num: number, fileName: string) => {
	// 	setTimeout(() => {
	// 		setProgressData(num * 10, fileName);
	// 		if (num * 10 === 100) {
	// 			resolve();
	// 			return;
	// 		}
	// 		mockmockProgress(resolve, num + 1, fileName);
	// 	}, 250);
	// };

	// const mockProgress = (num: number, fileName: string) => {
	// 	return new Promise<void>((resolve, reject) => {
	// 		mockmockProgress(resolve, num, fileName);
	// 	});
	// };

	const initiateUpload = async (file: File) => {
		try {
			const fileData = {
				arn: job.id,
				tenantId,
				tenantRegion: profile.awsRegion,
				userId: profile.id,
				filename: file.name,
				deviceId: `${tenantId}-stratus-portal`,
				stage,
				drop: job.drop
			};
			const response = await axios.post(`/api/node/initiateUpload`, fileData);
			const err: any = findErrors(response);
			if (!_.isEmpty(err)) {
				onError(file.name);
				return false;
			}
			return response.data;
		} catch (error) {
			console.error('Error in initiateUpload:', error);
			onError(file.name);
			return false;
		}
	};

	const uploadFileToDroppoint = async (file: File, droppoint: any) => {
		try {
			const formData = new FormData();
			formData.append('file', file);

			// await mockProgress(0, file.name);
			const response = await axios.put(droppoint.info.url, file, {
				transformRequest: [
					(data, headers: any) => {
						console.log(headers);
						if (headers?.common?.Authorization) {
							delete headers.common?.Authorization;
						}

						return data;
					}
				],
				headers: { ...droppoint.info.headers, 'Content-Type': file.type },
				onUploadProgress: progressEvent => {
					const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
					setProgressData(percentCompleted, file.name);
				}
			});
			const err: any = findErrors(response);
			if (!_.isEmpty(err)) {
				onError(file.name);
				return false;
			}
			return true;
		} catch (error) {
			setProgressData(0, ''); // Reset progress on error
			console.error('Error in uploadFileToDroppoint:', error);
			console.error('Error uploading to droppoint:', error);
			onError(file.name);
			return false;
		}
	};

	const finalizeUpload = async (file: File, droppoint: any, fileId: string) => {
		const uploadData = {
			arn: job.id,
			filename: file.name,
			originalSize: file.size,
			tenantId,
			tenantRegion: profile.awsRegion,
			userId: profile.id,
			workflowName: workflow.name,
			workflowId: workflow.id,
			deviceId: `${tenantId}-stratus-portal`,
			stage,
			droppoint,
			fileId
		};
		try {
			const response = await axios.post('/api/node/finalizeUpload', uploadData);
			const err: any = findErrors(response);
			if (!_.isEmpty(err)) {
				onError(file.name);
				return false;
			}
			return response.data;
		} catch (error) {
			console.error('Error in finalizeUpload:', error);
			onError(file.name);
			return false;
		}
	};

	const jobUpdate = async (responses: { id: string; response: { url: string; headers: any } }[]) => {
		try {
			const jobResponse = await axios.post(`${jobsApiUrl}/people/job/attachments`, {
				jobID: job.id,
				files: [
					...responses.map(({ response, id }) => [
						id,
						{
							url: response.url,
							headers: response.headers,
							uploadedBy: profile.id,
							date: Date.now()
						}
					])
				]
			});
			const err: any = findErrors(jobResponse);
			if (!_.isEmpty(err)) {
				dispatch(appActions.alert('jobs:file upload:fail', 'warning'));
				return false;
			}
			dispatch(appActions.alert('jobs:file upload:success', 'success'));

			return jobResponse.data;
		} catch (error) {
			console.error('Error in jobUpdate:', error);
			return false;
		}
	};

	setTotalProgress((prev: number) => 0);

	/* eslint-disable no-await-in-loop */
	// eslint-disable-next-line no-restricted-syntax
	for (const [index, file] of files.entries()) {
		setProgressData(0, file.name);
		const initiateResponse = await initiateUpload(file);
		if (initiateResponse && initiateResponse.droppoint && initiateResponse.fileId) {
			// if (index % 2 === 0 && index < 5) {
			// 	onError(file.name);
			// } else {
			// await uploadFileToDroppoint(file, {});
			// }
			const uploadResponse = await uploadFileToDroppoint(file, initiateResponse.droppoint);
			if (uploadResponse) {
				const finalizeResponse = await finalizeUpload(
					file,
					initiateResponse.droppoint,
					initiateResponse.fileId
				);
				if (finalizeResponse) {
					fileResponses.push({
						id: initiateResponse.fileId,
						response: finalizeResponse.downloadLink.info
					});
				}
			}
		}
		setTotalProgress(() => Math.round((100 / files.length) * (index + 1)));
	}
	await jobUpdate(fileResponses);
	/* eslint-enable no-await-in-loop */
	onSuccess();
	dispatch(getSelectedJob(job.id));
};

export const rejectJob = (jobID: string, reason: string, onSuccess = () => {}, onError = () => {}): AppThunk => async (
	dispatch,
	getState
) => {
	const profile = getProfile(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	try {
		await axios.put(`${jobsApiUrl}/people/job/action`, {
			jobID,
			action: 'reject',
			data: reason
		});
		onSuccess();
		dispatch({ type: RESET_SELECTED_JOB_SUCCESS });
	} catch (error) {
		dispatch(appActions.handleError(error, 'job:reject:fail'));
		onError();
	}
};
export const acceptJob = (jobID: string, onSuccess = () => {}, onError = () => {}): AppThunk => async (
	dispatch,
	getState
) => {
	const profile = getProfile(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	try {
		await axios.put(`${jobsApiUrl}/people/job/action`, {
			jobID,
			action: 'accept'
		});
		console.log('job', jobID, 'accepted');
		dispatch({ type: ACCEPT_JOB_SUCCESS });
		onSuccess();
		console.log('ACCEPT_JOB_SUCCESS dispatched');
	} catch (error) {
		dispatch(appActions.handleError(error, 'job:accept:fail'));
		onError();
	}
};

export const claimJob = (jobID: string, onSuccess = () => {}, onError = () => {}): AppThunk => async (
	dispatch,
	getState
) => {
	const profile = getProfile(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	try {
		await axios.put(`${jobsApiUrl}/people/job/action`, {
			jobID,
			action: 'claim'
		});
		console.log('job', jobID, 'claimed');
		dispatch({ type: ACCEPT_JOB_SUCCESS });
		dispatch(getSelectedJob(jobID));
		onSuccess();
	} catch (error) {
		dispatch(appActions.handleError(error, 'job:claim:fail'));
		onError();
	}
};

export const approveNodeSubmit = (
	// send to Positive transition
	jobID: string,
	nodeNamespace: string,
	onSuccess = () => {},
	onError = () => {}
): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const nodeUrl = getApproveNodesUrl(profile?.awsRegion, nodeNamespace);
	const tenantId = getSelectedLicenseGroupId(getState());
	const payload: any = {
		jobID,
		tenantId
	};
	try {
		await axios.post(`${nodeUrl}/api/node/approveJob`, payload);
		onSuccess();
		dispatch({ type: RESET_SELECTED_JOB_SUCCESS });
	} catch (error) {
		dispatch(appActions.handleError(error, 'job:submit:fail'));
		onError();
	}
};

export const approveNodeReject = (
	// send to Negative transition
	jobID: string,
	nodeNamespace: string,
	onSuccess = () => {},
	onError = () => {}
): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const nodeUrl = getApproveNodesUrl(profile?.awsRegion, nodeNamespace);
	const tenantId = getSelectedLicenseGroupId(getState());
	const payload: any = {
		jobID,
		tenantId
	};
	try {
		await axios.post(`${nodeUrl}/api/node/rejectJob`, payload);
		onSuccess();
		dispatch({ type: RESET_SELECTED_JOB_SUCCESS });
	} catch (error) {
		dispatch(appActions.handleError(error, 'job:submit:fail'));
		onError();
	}
};

export const submitJob = (
	jobID: string,
	allNextSteps: JobItemDetails['nextSteps'],
	selectedNextStep: string | null,
	onSuccess = () => {},
	onError = () => {}
): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	const payload: any = {
		jobID,
		action: 'done'
	};
	if (selectedNextStep) payload.data = allNextSteps.find(s => s.transition === selectedNextStep);
	console.log('job', jobID, 'submitted', payload);
	try {
		await axios.put(`${jobsApiUrl}/people/job/action`, payload);
		onSuccess();
		dispatch({ type: RESET_SELECTED_JOB_SUCCESS });
	} catch (error) {
		dispatch(appActions.handleError(error, 'job:submit:fail'));
		onError();
	}
};

export const reassignJob = (
	jobID: string,
	recipientUserID: string,
	onSuccess = () => {},
	onError = () => {}
): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	const payload: any = {
		jobID,
		action: 'reassign',
		data: recipientUserID
	};
	console.log(`job ${jobID} reassigned`, payload);
	try {
		await axios.put(`${jobsApiUrl}/people/job/action`, payload);
		onSuccess();
		dispatch({ type: RESET_SELECTED_JOB_SUCCESS });
	} catch (error) {
		dispatch(appActions.handleError(error, 'job:submit:fail'));
		onError();
	}
};

export const getJobTracker = (onSuccess = () => {}, onError = () => {}): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	try {
		const response = await axios.get(`${jobsApiUrl}/people/tracker`, {
			params: {
				userID: profile.id
			}
		});
		console.log('tracked jobs', response.data.jobs);
		dispatch({
			type: GET_TRACKER_SUCCESS,
			payload: groupByID(response.data.jobs)
		});
		onSuccess();
	} catch (error) {
		dispatch(appActions.handleError(error, 'tracker:get:fail'));
		onError();
	}
};

export const trackJob = (jobID: string, onSuccess = () => {}, onError = () => {}): AppThunk => async (
	dispatch,
	getState
) => {
	const profile = getProfile(getState());
	const trackedJobs = getTrackedJobs(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	try {
		await axios.post(`${jobsApiUrl}/people/tracker`, {
			jobs: [...Object.keys(trackedJobs), jobID]
		});
		console.log('tracking job', jobID);
		dispatch(getJobTracker());
		onSuccess();
	} catch (error) {
		dispatch(appActions.handleError(error, 'job:track:fail'));
		onError();
	}
};

export const untrackJob = (jobID: string): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const trackedJobs = getTrackedJobs(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	try {
		await axios.post(`${jobsApiUrl}/people/tracker`, {
			jobs: Object.keys(trackedJobs).filter(j => j !== jobID)
		});
		console.log('untracking job', jobID);
		dispatch(getJobTracker());
	} catch (error) {
		dispatch(appActions.handleError(error, 'job:untrack:fail'));
	}
};

export const createNewQueue = (
	name: JobQueue['name'],
	groupId: UserGroup['id'],
	users: Array<User['id']>,
	active: boolean
): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	const body = {
		name,
		active,
		groupID: groupId,
		members: users
	};
	try {
		await axios.post(`${jobsApiUrl}/people/queue`, body);
		console.log('creating new queue', body);
		dispatch(getTenantQueues());
	} catch (error) {
		dispatch(appActions.handleError(error, 'queue:create:fail'));
	}
};

export const updateQueue = (
	queueId: JobQueue['id'],
	name: JobQueue['name'],
	groupId: UserGroup['id'],
	users: Array<User['id']>,
	active: boolean,
	onSuccess = () => {},
	onError = () => {}
): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const tenantId = getSelectedLicenseGroupId(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	const body = {
		tenantID: tenantId,
		queueID: queueId,
		name,
		active,
		groupID: groupId,
		members: users
	};
	try {
		await axios.put(`${jobsApiUrl}/people/queue`, body);
		console.log(`updated queue ${queueId}`, body);
		dispatch(getTenantQueues());
		onSuccess();
	} catch (error) {
		dispatch(appActions.handleError(error, 'queue:update:fail'));
		onError();
	}
};

export const removeQueue = (queueId: JobQueue['id']): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const tenantId = getSelectedLicenseGroupId(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	const params = {
		tenantID: tenantId,
		queueID: queueId
	};
	try {
		await axios.delete(`${jobsApiUrl}/people/queue`, { params });
		console.log('removed queue', queueId);
		dispatch(getTenantQueues());
	} catch (error) {
		dispatch(appActions.handleError(error, 'queue:delete:fail'));
	}
};
export const removeUserFromQueues = (userId: User['id']): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const tenantId = getSelectedLicenseGroupId(getState());
	const jobsApiUrl = getNodeTestingAPI(profile?.awsRegion);
	const params = {
		tenantID: tenantId,
		userID: userId
	};
	try {
		await axios.delete(`${jobsApiUrl}/people/queues/user`, { params });
		console.log('removed from queues user', userId);
	} catch (error) {
		dispatch(appActions.handleError(error, 'queue:user:delete:fail'));
	}
};
