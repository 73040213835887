import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import useGetPageSection from 'app/hooks/useGetPageSection';
import { useSelector } from 'app/modules/react-redux';
import { getRolePermissionCheck, getSelectedLicenseGroupData, getFeaturePermissionCheck } from 'app/store/reducers';
import clsx from 'clsx';
import React from 'react';
import FuseNavItem, { registerComponent } from './FuseNavItem';
import FuseNavHorizontalCollapse from './horizontal/FuseNavHorizontalCollapse';
import FuseNavHorizontalGroup from './horizontal/FuseNavHorizontalGroup';
import FuseNavHorizontalItem from './horizontal/FuseNavHorizontalItem';
import FuseNavHorizontalLink from './horizontal/FuseNavHorizontalLink';
import FuseNavVerticalCollapse from './vertical/FuseNavVerticalCollapse';
import FuseNavVerticalGroup from './vertical/FuseNavVerticalGroup';
import FuseNavVerticalItem from './vertical/FuseNavVerticalItem';
import FuseNavVerticalLink from './vertical/FuseNavVerticalLink';
import NavVerticalQuickLinks from './vertical/NavVerticalQuickLinks';

/*
Register Fuse Navigation Components
 */
registerComponent('vertical-group', FuseNavVerticalGroup);
registerComponent('vertical-collapse', FuseNavVerticalCollapse);
registerComponent('vertical-item', FuseNavVerticalItem);
registerComponent('vertical-link', FuseNavVerticalLink);
registerComponent('horizontal-group', FuseNavHorizontalGroup);
registerComponent('horizontal-collapse', FuseNavHorizontalCollapse);
registerComponent('horizontal-item', FuseNavHorizontalItem);
registerComponent('horizontal-link', FuseNavHorizontalLink);
registerComponent('vertical-divider', () => <Divider className="my-16" />);
registerComponent('horizontal-divider', () => <Divider className="my-16" />);

const useStyles = makeStyles(theme => ({
	navigation: {
		'& .list-item': {
			'&:hover': {
				backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0,0,0,.04)'
			},
			'&:focus:not(.active)': {
				backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.06)' : 'rgba(0,0,0,.05)'
			}
		}
	},
	verticalNavigation: {
		'&.active-square-list': {
			'& .list-item, & .active.list-item': {
				width: '100%',
				borderRadius: '0'
			}
		},
		'&.dense': {
			'& .list-item': {
				paddingTop: 0,
				paddingBottom: 0,
				height: 32
			}
		}
	},
	horizontalNavigation: {
		'&.active-square-list': {
			'& .list-item': {
				borderRadius: '0'
			}
		},
		'& .list-item': {
			padding: '8px 12px 8px 12px',
			height: 40,
			minHeight: 40,
			'&.level-0': {
				height: 44,
				minHeight: 44
			},
			'& .list-item-text': {
				padding: '0 0 0 8px'
			}
		}
	},
	'@global': {
		'.popper-navigation-list': {
			'& .list-item': {
				padding: '8px 12px 8px 12px',
				height: 40,
				minHeight: 40,
				'& .list-item-text': {
					padding: '0 0 0 8px'
				}
			},
			'&.dense': {
				'& .list-item': {
					minHeight: 32,
					height: 32,
					'& .list-item-text': {
						padding: '0 0 0 8px'
					}
				}
			}
		}
	}
}));

type Props = {
	navigation: {
		id: string;
		translate: string;
		type: string;
		icon: string;
		url: string;
		section: keyof ReturnType<typeof useGetPageSection>;
		children?: Props['navigation'];
	}[];
	layout?: 'horizontal' | 'vertical';
	active: boolean;
	dense: boolean;
	className?: string;
};
function FuseNavigation(props: Props) {
	const classes = useStyles(props);
	let { navigation, layout = 'vertical', active, dense, className } = props;

	// const isProductionSubscriptionLicense = useSelector(getIsSelectedLicenseGroupProductionSubscriptionLicense);
	const rolePermissionCheck = useSelector(getRolePermissionCheck);
	const featurePermissionCheck = useSelector(getFeaturePermissionCheck);
	const licenseGroupData = useSelector(getSelectedLicenseGroupData);

	const pageSection = useGetPageSection();

	const navigationItemFilter = (_item: Props['navigation'][number]) => {
		switch (_item.id) {
			case 'workflows':
				return rolePermissionCheck('workflowsTab.workflowsSection.view');
			case 'dashboard':
				return (
					rolePermissionCheck('dataTab.reportsSection.view') && licenseGroupData?.region !== 'ca-central-1'
				);
			case 'devices':
				return rolePermissionCheck('devicesTab.devicesSection.view');
			case 'users':
				return rolePermissionCheck('usersTab.usersSection.view');
			case 'apps':
				return (
					rolePermissionCheck([
						'appsTab.internalSection.view',
						'appsTab.externalSection.view',
						'appsTab.urlSection.view'
					]) && licenseGroupData?.plan !== 'stratus-starter'
				);
			case 'forms':
				return rolePermissionCheck('formsTab.formsSection.view');
			case 'logs':
				return rolePermissionCheck('dataTab.logsSection.view');
			case 'roles':
				return (
					featurePermissionCheck('advanced_roles_and_permissions') &&
					rolePermissionCheck('rolesTab.rolesSection.view') &&
					licenseGroupData?.plan !== 'stratus-starter'
				);
			case 'home':
				return licenseGroupData?.plan !== 'stratus-starter';
			case 'jobs':
				return (
					featurePermissionCheck('home_page') &&
					rolePermissionCheck('jobsTab.tasksSection.view') &&
					licenseGroupData?.plan !== 'stratus-starter'
				);
			case 'job-queue':
				return (
					featurePermissionCheck('home_page') &&
					rolePermissionCheck('jobsTab.jobQueueSection.view') &&
					licenseGroupData?.plan !== 'stratus-starter' &&
					licenseGroupData?.plan !== 'stratus-business'
				);
			case 'tracker':
				return (
					featurePermissionCheck('home_page') &&
					rolePermissionCheck('jobsTab.trackerSection.view') &&
					licenseGroupData?.plan !== 'stratus-starter'
				);
			case 'naming':
				return rolePermissionCheck('jobsTab.namingSection.view');
			default:
				return true;
		}
	};

	const filterNavigationItems = (navigationItems: Props['navigation']): Props['navigation'] => {
		return navigationItems
			.filter(navigationItemFilter)
			.map((_item: Props['navigation'][number]) => ({
				..._item,
				children: _item.children ? filterNavigationItems(_item.children) : _item.children
			}))
			.filter(({ children }) => (Array.isArray(children) ? children.length > 0 : true));
	};

	navigation = filterNavigationItems(navigation);
	const pageSectionNavigation = navigation.filter(({ section }) => pageSection[section]);

	const verticalNav = (
		<List
			className={clsx(
				'navigation whitespace-no-wrap py-24',
				classes.navigation,
				classes.verticalNavigation,
				`active-${active}-list`,
				dense && 'dense',
				className
			)}
		>
			{pageSection.admin && <NavVerticalQuickLinks />}

			{pageSectionNavigation.map(_item => (
				<FuseNavItem key={_item.id} type={`vertical-${_item.type}`} item={_item} nestedLevel={0} />
			))}
		</List>
	);

	const horizontalNav = (
		<List
			className={clsx(
				'navigation whitespace-no-wrap flex p-0',
				classes.navigation,
				classes.horizontalNavigation,
				`active-${active}-list`,
				dense && 'dense',
				className
			)}
		>
			{pageSectionNavigation.map(_item => (
				<FuseNavItem
					key={_item.id}
					type={`horizontal-${_item.type}`}
					item={_item}
					nestedLevel={0}
					dense={dense}
				/>
			))}
		</List>
	);

	if (pageSectionNavigation.length > 0) {
		switch (layout) {
			case 'horizontal': {
				return horizontalNav;
			}
			case 'vertical':
			default: {
				return verticalNav;
			}
		}
	} else {
		return null;
	}
}

export default React.memo(FuseNavigation);
